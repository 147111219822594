import Quill from 'quill';

const MentionBlot = Quill.import('formats/mention');

class VcMentionBlot extends MentionBlot {
  constructor(scroll, node) {
    super(scroll, node);
    // figure out how to edit blot
    // this.contentNode.setAttribute('contenteditable', true);
  }

  //   static setDataValues(element, data) {
  //     const domNode = element;
  //     alert();
  //     domNode.dd.d();
  //     domNode.classList.add(
  //       data.denotationChar === '@' ? 'vc-mention' : 'vc-hastag'
  //     );
  //     Object.keys(data).forEach((key) => {
  //       domNode.dataset[key] = data[key];
  //     });
  //     return domNode;
  //   }

  attach() {
    super.attach();
    this.clickHandler = (e) => {
      const event = new Event('mention-clicked', {
        bubbles: true,
        cancelable: true,
      });
      event.value = Object.assign({}, this.domNode.dataset);
      event.event = e;
      window.dispatchEvent(event);
      e.stopPropagation();
      e.preventDefault();
    };
    this.domNode.addEventListener('click', this.clickHandler, false);
  }
}

VcMentionBlot.blotName = 'vc-mention';

Quill.register(VcMentionBlot);

<template>
  <c-flex
    align="start"
    :w="isReply ? '100%' : { base: '100%', lg: '70%' }"
    :maxWidth="isReply ? '100%' : { base: '100%', lg: '70%' }"
    :mb="isReply ? 2 : 0"
  >
    <c-image
      :name="comment.user.firstname + ' ' + comment.user.lastname"
      w="35px"
      mr="4"
      rounded="lg"
      h="35px"
      :src="comment.user.profilePhoto ?? '/no_image.svg'"
      alt="user-avatar"
    />
    <c-box flex="1">
      <c-flex align="center" mb="2" justify="space-between">
        <c-text style="white-space: nowrap" color="vc-orange.400" fontWeight="600" fontSize="xs">
          {{ comment.user.firstname }} {{ comment.user.lastname }}
        </c-text>
        <c-flex align="center">
          <c-text fontSize="10px" v-if="comment.isEdited" mr="2">
            (edited)
          </c-text>
          <c-text style="white-space: nowrap" color="gray.400" class="ml-2" fontWeight="600" fontSize="10px">
            {{ comment.createdAt | moment('from') }}
          </c-text>
          <c-popover placement="bottom" v-if="isMyComment">
            <c-popover-trigger>
              <c-link ml="4" mr="3">
                <svg
                  v-chakra="{
                    width: '10px',
                    height: '16px',
                    fill: '#ef5323',
                  }"
                >
                  <use href="@/assets/icons/dots-horizontal.svg#dots"></use>
                </svg>
              </c-link>
            </c-popover-trigger>
            <c-popover-content w="max-content">
              <c-popover-body p="0">
                <c-list fontSize="sm">
                  <c-list-item>
                    <c-pseudo-box
                      :_hover="{
                        bg: '#e6e4e483',
                      }"
                      cursor="pointer"
                      @click="onEditClick"
                      p="3"
                    >
                      Edit Comment
                    </c-pseudo-box>
                  </c-list-item>
                  <c-list-item>
                    <c-pseudo-box
                      :_hover="{
                        bg: '#e6e4e483',
                      }"
                      cursor="pointer"
                      @click="onDeleteClick"
                      p="3"
                    >
                      Delete Comment
                    </c-pseudo-box>
                  </c-list-item>
                </c-list>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
        </c-flex>
      </c-flex>
      <c-flex
        bg="#fff"
        w="100%"
        minH="50px"
        h="auto"
        align="center"
        px="3"
        borderRadius="0 12px 12px"
        fontSize="sm"
      >
        <c-text class="comment__content" v-html="parsedContent"></c-text>
      </c-flex>
      <c-flex pl="2" mt="2" align="center">
        <c-link mr="4" display="flex" v-if="!isReply" alignItems="center">
          <svg
            v-chakra="{
              fill: 'gray.400',
              w: '16px',
              h: '16px',
              mr: '2',
            }"
          >
            <use href="@/assets/icons/icon-chat.svg#chat"></use>
          </svg>
          <c-text color="gray.400" fontSize="sm">{{ replies.length }}</c-text>
        </c-link>
        <c-link
          @click="onReplyClick($event, comment)"
          fontWeight="600"
          v-if="!isReply"
          fontSize="xs"
        >
          Reply
        </c-link>
        <c-link
          @click="isRepliesVisible = !isRepliesVisible"
          ml="5"
          fontWeight="500"
          v-if="replies.length"
          fontSize="xs"
        >
          {{ isRepliesVisible ? 'Hide' : 'Show' }} replies
        </c-link>
        <!-- <svg
          v-chakra="{
            fill: 'gray.400',
            w: '16px',
            h: '16px',
            mr: '2',
          }"
        >
          <use href="@/assets/icons/icon-heart.svg#heart"></use>
        </svg>
        <c-text color="gray.400" fontSize="sm">4</c-text> -->
      </c-flex>
      <template v-if="replies.length">
        <c-stack :stack="2" pl="4" pt="3" v-show="isRepliesVisible">
          <c-box v-for="response in replies" :key="response.id">
            <Comment
              @editComment="onEditComment(response)"
              @deleteComment="onDeleteComment(comment)"
              :comment="response"
              :isReply="true"
            />
          </c-box>
        </c-stack>
      </template>
      <c-box w="100%" v-if="isReplyBoxVisible || isEditMode" mt="2">
        <c-flex
          align="center"
          w="100%"
          maxWidth="100%"
          borderWidth="1px"
          borderColor="gray.400"
          borderRadius="4px"
        >
          <c-box
            flexBasis="calc(100% - 40px)"
            flexGrow="0"
            minHeight="40px"
            maxWidth="100%"
          >
            <quill-editor
              style="z-index: 999"
              class="editor"
              ref="textEditor"
              :spellcheck="false"
              v-model="newComment"
              @change="onChange"
              :options="editorOptions"
            />
          </c-box>
          <c-button
            px="0"
            border="none"
            :disabled="!newComment || !newComment.trim()"
            display="flex"
            justifyContent="center"
            variant="outline"
            @click="submitComment"
          >
            <c-spinner
              v-if="isSavingComment"
              color="blue.400"
              thickness="2px"
            ></c-spinner>
            <svg
              v-else
              v-chakra="{
                fill: 'gray.400',
                w: '16px',
                h: '16px',
              }"
            >
              <use href="@/assets/icons/icon-send-button.svg#send"></use>
            </svg>
          </c-button>
        </c-flex>
      </c-box>
    </c-box>
    <c-alert-dialog
      :is-open="isDeleteDialogOpen"
      :least-destructive-ref="$refs.cancelRef"
      :on-close="closeDeleteDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Delete Comment
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Are you sure? You can't undo this action afterwards.
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeDeleteDialog">
            Cancel
          </c-button>
          <c-button variantColor="red" @click="deleteComment" ml="3">
            Delete
            <c-spinner
              ml="3"
              v-if="isDeletingComment"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-flex>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import { mapState } from 'vuex';

import {
  addPostComment,
  getMatchingTags,
  getMatchingUsers,
  updatePostComment,
} from '@/services/insight';

import { Quill } from 'vue-quill-editor';

import 'quill-mention/dist/quill.mention.min.css';

import 'quill-mention';

import '@/helpers/editor/blots/mention';
import { generateID } from '@/helpers/data';

export default {
  name: 'Comment',
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
    isReply: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReplyBoxVisible: false,
      newComment: null,
      editorOptions: {
        placeholder: `Reply...`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
          keyboard: {
            bindings: {
              shift_enter: {
                key: 13,
                shiftKey: true,
                handler: (range) => {
                  this.editor.insertText(range.index, '\n');
                },
              },
              enter: {
                key: 13,
                handler: () => {
                  this.addComment();
                },
              },
            },
          },
        },
      },
      isSavingComment: false,
      isEditMode: false,
      editingComment: null,
      replies: [],
      isRepliesVisible: false,
      isDeleteDialogOpen: false,
      commentToDelete: null,
      isDeletingComment: false,
    };
  },
  created() {
    this.replies = cloneDeep(this.comment.replies || []);
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isMyComment() {
      return this.user.id === this.comment.userId;
    },
    parsedContent() {
      return this.parseMentions(this.comment.content);
    },
    editor() {
      return this.$refs.textEditor.quill;
    },
  },
  watch: {
    comment() {
      this.replies = cloneDeep(this.comment.replies || []);
    },
  },
  methods: {
    parseMentions(content) {
      return content.replace(
          /<span class="mention"[^>]*data-id="(\d+)"[^>]*data-value="([^"]+)".*?>.*?<span class="ql-mention-denotation-char">@<\/span>(.*?)<\/span>/g,
          (match, id, value, name) => {
            // Flatten the structure by combining the @ symbol and the name
            return `<a href="/app/profile/user/${id}" class="mention-link">@${name}</a>`;
          }
      );
    },
    onReplyClick() {
      this.isReplyBoxVisible = !this.isReplyBoxVisible;
    },
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      const textBeforeCursorPos = this.editor.getText(
        startPos,
        cursorPosition - startPos
      );
      return textBeforeCursorPos;
    },
    onChange() {
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    submitComment() {
      if (this.isEditMode) {
        this.updateComment();
      } else {
        this.addComment();
      }
    },
    async addComment() {
      const {
        id: userId,
        email,
        profilePhoto,
        firstname,
        lastname,
      } = this.user;
      const data = {
        content: this.newComment,
        postId: this.comment.postId,
        replyTo: this.comment.id,
      };
      const commentId = generateID(4);
      this.replies.push({
        id: commentId,
        postId: this.comment.postId,
        userId,
        user: { id: userId, email, profilePhoto, firstname, lastname },
        content: this.newComment,
        replyTo: this.comment.id,
        createdAt: new Date().toISOString(),
      });
      this.newComment = null;
      const commentIndex = this.replies.findIndex(
        (comment) => comment.id == commentId
      );
      try {
        const res = await addPostComment(data);
        this.replies[commentIndex] = cloneDeep(
          res.data.insert_post_comment_one
        );
        this.isReplyBoxVisible = false;
      } catch (e) {
        this.replies.splice(commentIndex, 1);
        this.$toast({
          title: 'An error occurred.',
          description: `Could not reply to comment, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onEditClick() {
      this.$emit('editComment');
    },
    onDeleteClick() {
      this.$emit('deleteComment');
    },
    onEditComment(comment) {
      this.editingComment = comment;
      this.isEditMode = true;
      this.newComment = comment.content;
      this.$nextTick(() => {
        this.editor.setSelection(this.editor.getLength(), 0);
      });
    },
    async updateComment() {
      if (this.newComment === this.editingComment.content) {
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
        return;
      }
      this.isSavingComment = true;
      const commentIndex = this.replies.findIndex(
        (comment) => comment.id === this.editingComment.id
      );
      try {
        const res = await updatePostComment({
          id: this.editingComment.id,
          set: { isEdited: true, content: this.newComment },
        });
        this.replies[commentIndex].content =
          res.data.update_post_comment_by_pk.content;
        this.replies[commentIndex].isEdited = true;
        this.isSavingComment = false;
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
      } catch (e) {
        console.log({ e });
        this.isSavingComment = false;
      }
    },
    onDeleteComment(comment) {
      this.isDeleteDialogOpen = true;
      this.commentToDelete = { ...comment };
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.commentToDelete = null;
    },
    async deleteComment() {
      this.isDeletingComment = true;
      try {
        await updatePostComment({
          id: this.commentToDelete.id,
          set: { isDeleted: true },
        });
        const commentIndex = this.replies.findIndex(
          (comment) => comment.id === this.commentToDelete.id
        );
        this.replies.splice(commentIndex, 1);
        this.closeDeleteDialog();
        this.$toast({
          title: 'Success!!!',
          description: `Comment has been deleted`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.isDeletingComment = false;
      } catch (e) {
        this.isDeletingComment = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Could not delete post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mention-link {
  color: #007bff;
  font-weight: bold;
}

.mention-link:hover {
  text-decoration: underline;
}
.editor {
  @apply max-w-full;
  ::v-deep {
    .ql-container {
      font-size: inherit;
      max-height: 70px;
      overflow: visible !important;
      overflow-y: scroll;
      font-family: inherit;
      border: none;
    }
    .mention {
      @apply font-bold bg-transparent
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}

.comment__content {
  ::v-deep p {
    a {
      color: rgba(59, 130, 246, 1) !important;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
